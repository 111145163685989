export const AVAILABLE_FACET_TYPES = [
  {
    name: 'institution',
    attribute: 'dataProvider',
  },
  {
    name: 'date',
    attribute: 'dateMin',
    customInterface: 'date',
  },
  {
    name: 'isPartOf',
    attribute: 'isPartOf',
  },
  {
    name: 'subject',
    attribute: 'subject',
  },
  {
    name: 'objectType',
    attribute: 'dcType',
  },
  {
    name: 'medium',
    attribute: 'medium',
  },
  {
    name: 'creator',
    attribute: 'creator',
  },
  {
    name: 'publisher',
    attribute: 'publisher',
    borderAfter: true,
  },
  {
    name: 'rightOfUse',
    attribute: 'edmRightsName',
  },
  {
    name: 'reuse',
    attribute: 'edmRightsReusePolicy',
    locale: 'reuse',
  },
  {
    name: 'mediaType',
    attribute: 'edmType',
    locale: 'mediaType',
  },
]

export const AVAILABLE_SORTINGS = [
  { value: 'chos', label: 'relevance', icon: 'bi-sort-down-alt' },
  {
    value: 'chos/sort/dateMin:asc',
    label: 'date',
    direction: 'asc',
    icon: 'bi-sort-numeric-down',
  },
  {
    value: 'chos/sort/dateMin:desc',
    label: 'date',
    direction: 'desc',
    icon: 'bi-sort-numeric-up',
  },
  {
    value: 'chos/sort/dataProvider:asc',
    label: 'institution',
    direction: 'asc',
    icon: 'bi-sort-alpha-down',
  },
  {
    value: 'chos/sort/dataProvider:desc',
    label: 'institution',
    direction: 'desc',
    icon: 'bi-sort-alpha-up',
  },
  {
    value: 'chos/sort/titleSort:asc',
    label: 'title',
    direction: 'asc',
    icon: 'bi-sort-alpha-down',
  },
  {
    value: 'chos/sort/titleSort:desc',
    label: 'title',
    direction: 'desc',
    icon: 'bi-sort-alpha-up',
  },
]

export const PAGE_SIZE = 50

export const DEBOUNCE_MILLISECONDS = 200

export const INDEX_NAME = 'chos'
