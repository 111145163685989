<script setup lang="ts">
const { t, te } = useI18n()

defineProps({
  error: {
    type: Object,
    required: true,
  },
})

const handleError = () => clearError({ redirect: '/' })

useColorMode({
  emitAuto: true,
  modes: {
    contrast: 'dark contrast',
  },
})

const showStack = ref(false)
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <main class="error-page">
    <ClientOnly>
      <NotFoundCHO />
    </ClientOnly>

    <h1 class="error-message">
      {{
        `${te(error.message) ? t(error.message) : error.message}  (${error.statusCode})`
      }}
    </h1>

    <div class="button-group">
      <TextButton
        :title="t('errors.backHome')"
        icon-class="bi-box-arrow-left"
        @click="handleError"
      />

      <TextButton
        v-if="error.stack && !showStack"
        :title="t('errors.showStack')"
        icon-class="bi-list"
        @click="() => (showStack = true)"
      />
    </div>

    <div v-if="showStack" class="stack" v-html="error.stack"></div>
  </main>
</template>

<style lang="scss" scoped>
.error-page {
  background-color: var(--color-background);
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

h1 {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 3em;
  padding: calc(var(--layout-margin-h) + 0.6rem);
}

.error-message {
  font-family: monospace;
  margin: 1em 0;
}

.stack {
  font-family: monospace;
  text-align: left;
  max-width: 80%;
  margin: 8em auto;
}

.button-group {
  display: inline-flex;
  gap: 3em;
  flex-direction: column;
  align-items: center;
}

@include media('>=small') {
  h1 {
    font-size: 1.6rem;
  }
}

@include media('>=medium') {
  h1 {
    font-size: 2rem;
  }
}
</style>
