import validate from "/usr/src/kulturpool-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import _0_45init_45stores_45global from "/usr/src/kulturpool-frontend/middleware/0-init-stores.global.ts";
import _1_45ensure_45page_45exists_45global from "/usr/src/kulturpool-frontend/middleware/1-ensure-page-exists.global.js";
import _2_45track_45history_45global from "/usr/src/kulturpool-frontend/middleware/2-track-history.global.js";
import manifest_45route_45rule from "/usr/src/kulturpool-frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _0_45init_45stores_45global,
  _1_45ensure_45page_45exists_45global,
  _2_45track_45history_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}