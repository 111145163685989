import { flipTranslatedObject } from '../utils/restructure'
import { IndexCho } from '~/models/IndexCho'

export const useSearchStore = defineStore('search', () => {
  /* === init === */
  const { $directus, $readItems } = useNuxtApp()
  const dataEnv = useRuntimeConfig().public.dataEnv

  /* === state === */
  const allDemoSearches: Ref<DemoSearchPayload[]> = ref([])
  const latestChoInPreview: Ref<IndexCho | null> = ref(null)
  const totalHits: Ref<number> = ref(0)
  const choInPreview: Ref<IndexCho | null> = ref(null)
  const renderStatus: Ref<RenderStatus | null> = ref(null)
  const tilesInView: Ref<Set<any>> = ref(new Set())
  const currentViewType = ref('tiles')
  const contextField = ref(
    AVAILABLE_FACET_TYPES.find((item) => item.name === 'medium')
  )
  const results = ref(null)
  const hits: Ref<IndexCho[]> = ref([])

  /* === getters === */
  const demoSearches = computed(() =>
    shuffle(
      allDemoSearches.value
        .map(flipTranslatedObject)
        .filter((item) => item.in_demo_search === true)
    )
  )
  const isChoInPreview = computed(() => {
    return choInPreview.value !== null
  })
  const lastTileInView = computed(() =>
    tilesInView.value.size === 0
      ? null
      : Math.max(...Array.from(tilesInView.value))
  )

  /* === actions === */
  async function initDemoSearches() {
    try {
      const data = await $directus().request(
        $readItems('demo_searches', {
          filter: {
            envs: { _contains: dataEnv },
          },
          fields: [
            'envs',
            'color_background',
            'color_text',
            'in_demo_search',
            'url',
            { image: ['*'] },
            {
              translations: ['name', 'summary', 'languages_code'],
            },
          ],
        })
      )
      allDemoSearches.value = data
    } catch (err) {
      console.warn(err)
    }
  }

  function setTotalHits(value: number) {
    totalHits.value = value
  }

  function setRenderStatus(value: RenderStatus) {
    renderStatus.value = value
  }

  function setActionsAfterRender(nextFunction: (...args: any[]) => any) {
    const stopWatch = watch(renderStatus, (newValue, oldValue) => {
      if (
        (newValue === 'idle' && oldValue === 'loading') ||
        (newValue === 'idle' && oldValue === 'stalled')
      ) {
        setTimeout(() => {
          nextFunction()
          stopWatch()
        }, 10)
      }
    })
  }

  function setActiveItem(item: IndexCho) {
    choInPreview.value = item
  }

  function dropActiveItem() {
    latestChoInPreview.value = choInPreview.value
    choInPreview.value = null
  }

  function toggleViewType() {
    currentViewType.value = currentViewType.value === 'tiles' ? 'grid' : 'tiles'
  }

  return {
    // state
    _demoSearches: allDemoSearches,
    totalHits,
    renderStatus,
    choInPreview,
    results,
    hits,
    contextField,
    currentViewType,
    // getters
    latestChoInPreview,
    demoSearches,
    isItemActive: isChoInPreview,
    tilesInView,
    lastTileInView,
    // actions
    initDemoSearches,
    setTotalHits,
    setRenderStatus,
    setActiveItem,
    dropActiveItem,
    setActionsAfterRender,
    toggleViewType,
  }
})
