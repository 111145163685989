import type { HeroImagePayload, InstitutionPayload } from '../types/payloads'
import type {
  TranslatedInterface,
  OptionalTranslatedInterface,
} from '../types/translation'

export class Institution {
  constructor(
    // admin
    public name: string,
    public intermediate_provider: boolean,
    // master data
    public logo: string | null,
    public favicon: string | null,
    public hero_image: HeroImagePayload | null,
    public title: TranslatedInterface<string>,
    public title_official: TranslatedInterface<string>,
    public slug: TranslatedInterface<string>,
    public place: OptionalTranslatedInterface<string>,
    public summary: OptionalTranslatedInterface<string>,
    public content: OptionalTranslatedInterface<string>,
    public website_url: string | null,
    public web_collection_url: string | null
  ) {}

  public static fromPayload(payload: InstitutionPayload): Institution {
    const intermediate_provider = payload.intermediate_provider || false
    const logo = payload.logo ? payload.logo.id : null
    const favicon = payload.favicon ? payload.favicon.id : null

    const title = {} as TranslatedInterface<string>
    const title_official = {} as TranslatedInterface<string>
    const slug = {} as TranslatedInterface<string>

    const place = {} as OptionalTranslatedInterface<string>
    const summary = {} as OptionalTranslatedInterface<string>
    const content = {} as OptionalTranslatedInterface<string>

    if (payload.translations !== null) {
      for (const translation of payload.translations) {
        const language = translation.languages_code as string

        title[language as keyof TranslatedInterface<string>] = translation.title
        title_official[language as keyof TranslatedInterface<string>] =
          translation.title_official
        slug[language as keyof TranslatedInterface<string>] = translation.slug

        place[language as keyof TranslatedInterface<string>] = translation.place
        summary[language as keyof TranslatedInterface<string>] =
          translation.summary
        content[language as keyof TranslatedInterface<string>] =
          translation.content
      }
    }

    return new Institution(
      payload.name,
      intermediate_provider,
      logo,
      favicon,
      payload.hero_image,
      title,
      title_official,
      slug,
      place,
      summary,
      content,
      payload.website_url,
      payload.web_collection_url
    )
  }
}
