import Typesense from 'typesense'
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'

export function useTypesenseClientConfig(renderingType) {
  const config = useRuntimeConfig()

  let tsUrls

  if (renderingType === 'ssr')
    tsUrls = config.public.typesenseServerSideUrl
      .split(',')
      .map((url) => url.trim())
  if (renderingType === 'csr')
    tsUrls = config.public.typesenseClientSideUrl
      .split(',')
      .map((url) => url.trim())

  const nodes = tsUrls
    .map((url) => new URL(url))
    .map((url) => ({
      host: url.hostname,
      port: url.port,
      // slicing away the ':' of the protocol
      protocol: url.protocol.slice(0, -1),
    }))

  return {
    apiKey: config.public.typesenseApiKey,
    nodes,
  }
}

let SSRClient
let CSRClient

export function useTypesenseClient() {
  if (import.meta.server) {
    if (SSRClient !== undefined) return SSRClient
    SSRClient = new Typesense.Client({
      ...useTypesenseClientConfig('ssr'),
      connectionTimeoutSeconds: 2,
    })
    return SSRClient
  }

  if (import.meta.client) {
    if (CSRClient !== undefined) return CSRClient
    CSRClient = new Typesense.Client({
      ...useTypesenseClientConfig('csr'),
      connectionTimeoutSeconds: 2,
    })
    return CSRClient
  }
}

let adapter

export function useTypesenseInstantsearchAdapter() {
  if (adapter !== undefined) return adapter
  adapter = new TypesenseInstantSearchAdapter({
    server: {
      ...useTypesenseClientConfig('csr'),
      cacheSearchResultsForSeconds: 2 * 60,
    },
    additionalSearchParameters: {
      query_by:
        'title, description, dataProvider, dcType, isPartOf, subject, medium, contributor, coverage, creator, identifier, publisher, alternative, spatial, temporal',
      use_cache: true,
    },
    facetableFieldsWithSpecialCharacters: AVAILABLE_FACET_TYPES.map(
      (item) => item.attribute
    ),
  })
  return adapter
}
