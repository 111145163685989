import { readItem, readItems } from '@directus/sdk'
import { useDirectusClient } from '../composables/use-directus'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      directus: () => useDirectusClient(),
      readItem,
      readItems,
    },
  }
})
