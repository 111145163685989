import { useStorage } from '@vueuse/core'
import { IndexCho } from '~/models/IndexCho'

export const useFavoritesStore = defineStore('favoritesStore', () => {
  /* === state === */
  const favorites: Ref<FavoriteCho[]> = ref([])

  /* === getters === */
  const favoritesIds = computed(() => favorites.value.map((item) => item.id))

  function storeFavorites() {
    localStorage.setItem(
      'kulturpool-favorites',
      JSON.stringify(Array.from(favorites.value))
    )
  }

  function toggleFavorite(cho: FavoriteCho) {
    const foundIndex = favorites.value.findIndex(
      (activeFavorite) => activeFavorite.id === cho.id
    )
    if (foundIndex === -1) {
      favorites.value.push(cho)
    } else {
      favorites.value.splice(foundIndex, 1)
    }
    storeFavorites()
  }

  function isInFavorites(cho: IndexCho) {
    return favoritesIds.value.includes(cho.id)
  }

  /* === actions === */

  function initFavorites() {
    const state = useStorage('kulturpool-favorites', null)
    if (state.value !== null) {
      favorites.value = JSON.parse(state.value)
    }
  }

  function clearAllFavorites() {
    favorites.value = []
    storeFavorites()
  }

  function exportFavorites() {
    const { $i18n } = useNuxtApp()

    const exportChos: ExportCho[] = favorites.value.map((favorite) => {
      const res = {} as ExportCho

      const { institutionTitle, route, title } = useGracefulChoData(favorite)

      res.institutionTitle = institutionTitle
      res.route = new URL(route.fullPath, window.location.origin).toString()
      res.isShownAt = favorite.isShownAt
      res.title = title

      return res as ExportCho
    })

    const columnTitles = [
      'columnTitleInstitution',
      'columnTitleTitle',
      'columnTitleInstitutionLink',
      'columnTitleKulturpoolLink',
    ].map((heading) => $i18n.t(`favorites.${heading}`))

    exportToSpreadsheet(exportChos, columnTitles)
  }

  return {
    // state
    favorites,
    // getters
    favoritesIds,
    // actions
    initFavorites,
    toggleFavorite,
    isInFavorites,
    clearAllFavorites,
    exportFavorites,
  }
})
