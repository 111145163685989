<script setup>
const { t } = useI18n()

const cookieAssortment = [
  { id: 'i18n', necessary: true },
  { id: 'media-iframes', necessary: false },
  { id: 'sketchfab', necessary: false },
  { id: 'marker-io', necessary: false },
]

const cookieConsent = useCookie('kulturpool-cookie-consent', {
  // age in seconds (3 days):
  maxAge: 60 * 60 * 24 * 3,
})

const showCookieBanner = computed(() => !cookieConsent.value)

function handleFullConsent() {
  cookieConsent.value = cookieAssortment
    .map((cookieSet) => cookieSet.id)
    .join(encodeURIComponent('|'))
}

function handleNecessaryConsent() {
  cookieConsent.value = cookieAssortment
    .filter((cookieSet) => cookieSet.necessary)
    .map((cookieSet) => cookieSet.id)
    .join(encodeURIComponent('|'))
}
</script>

<template>
  <aside v-if="showCookieBanner" class="cookie-banner">
    <div class="info">
      <CookieBannerDeclaration />
    </div>

    <ul class="button-list">
      <li>
        <button @click="handleFullConsent">{{ t('cookies.acceptAll') }}</button>
      </li>
      <li>
        <button @click="handleNecessaryConsent">
          {{ t('cookies.acceptNecessary') }}
        </button>
      </li>
    </ul>
  </aside>
</template>

<style scoped lang="scss">
.cookie-banner {
  z-index: 20;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  left: 0;
  border-top: var(--border-layout);
  background-color: var(--color-background);
  max-width: 100vw;

  .info {
    padding: var(--layout-margin-h);
  }

  .button-list {
    padding: 0 var(--layout-margin-h) var(--layout-margin-h);
    height: 100%;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    width: 100%;
    justify-content: right;

    button {
      @extend %button-small;
      white-space: nowrap;
      width: 80%;
      margin: 1rem auto;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }
}

@include media('>=large') {
  .cookie-banner {
    flex-direction: row;

    .button-list {
      display: flex;
      padding: var(--layout-margin-h);
      justify-content: right;
      flex-direction: row;

      button {
        width: unset;
      }
    }
  }
}
</style>
