import { default as _91content_45id_93hZGGKRNctfMeta } from "/usr/src/kulturpool-frontend/pages/content-id/[content-id].vue?macro=true";
import { default as indexOj8ApCubCCMeta } from "/usr/src/kulturpool-frontend/pages/content-id/index.vue?macro=true";
import { default as indexmbs3A4oM7kMeta } from "/usr/src/kulturpool-frontend/pages/content/[slug]/index.vue?macro=true";
import { default as index2nQu8hdfJ1Meta } from "/usr/src/kulturpool-frontend/pages/content/index.vue?macro=true";
import { default as indexioq7jxHFVXMeta } from "/usr/src/kulturpool-frontend/pages/devtools/index.vue?macro=true";
import { default as indexvDW6QBURS9Meta } from "/usr/src/kulturpool-frontend/pages/index.vue?macro=true";
import { default as _91identifier_93DHIWCt4p63Meta } from "/usr/src/kulturpool-frontend/pages/institutions/[institution]/[identifier].vue?macro=true";
import { default as indexGwh7fBwVneMeta } from "/usr/src/kulturpool-frontend/pages/institutions/[institution]/index.vue?macro=true";
import { default as indexjJYmGLXDW9Meta } from "/usr/src/kulturpool-frontend/pages/institutions/index.vue?macro=true";
import { default as searchCEWJ7kIHjOMeta } from "/usr/src/kulturpool-frontend/pages/search.vue?macro=true";
export default [
  {
    name: "content-id-contentid___de",
    path: "/inhalt-id/:contentid()",
    component: () => import("/usr/src/kulturpool-frontend/pages/content-id/[content-id].vue").then(m => m.default || m)
  },
  {
    name: "content-id-contentid___en",
    path: "/en/content-id/:contentid()",
    component: () => import("/usr/src/kulturpool-frontend/pages/content-id/[content-id].vue").then(m => m.default || m)
  },
  {
    name: "content-id___de",
    path: "/content-id",
    redirect: indexOj8ApCubCCMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/content-id/index.vue").then(m => m.default || m)
  },
  {
    name: "content-id___en",
    path: "/en/content-id",
    redirect: indexOj8ApCubCCMeta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/content-id/index.vue").then(m => m.default || m)
  },
  {
    name: "content-slug___de",
    path: "/inhalte/:slug()",
    component: () => import("/usr/src/kulturpool-frontend/pages/content/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "content-slug___en",
    path: "/en/content/:slug()",
    component: () => import("/usr/src/kulturpool-frontend/pages/content/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "content___de",
    path: "/inhalte",
    redirect: index2nQu8hdfJ1Meta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/content/index.vue").then(m => m.default || m)
  },
  {
    name: "content___en",
    path: "/en/content",
    redirect: index2nQu8hdfJ1Meta?.redirect,
    component: () => import("/usr/src/kulturpool-frontend/pages/content/index.vue").then(m => m.default || m)
  },
  {
    name: "devtools___de",
    path: "/devtools",
    meta: indexioq7jxHFVXMeta || {},
    component: () => import("/usr/src/kulturpool-frontend/pages/devtools/index.vue").then(m => m.default || m)
  },
  {
    name: "devtools___en",
    path: "/en/devtools",
    meta: indexioq7jxHFVXMeta || {},
    component: () => import("/usr/src/kulturpool-frontend/pages/devtools/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/usr/src/kulturpool-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/usr/src/kulturpool-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "institutions-institution-identifier___de",
    path: "/institutionen/:institution()/:identifier()",
    meta: _91identifier_93DHIWCt4p63Meta || {},
    component: () => import("/usr/src/kulturpool-frontend/pages/institutions/[institution]/[identifier].vue").then(m => m.default || m)
  },
  {
    name: "institutions-institution-identifier___en",
    path: "/en/institutions/:institution()/:identifier()",
    meta: _91identifier_93DHIWCt4p63Meta || {},
    component: () => import("/usr/src/kulturpool-frontend/pages/institutions/[institution]/[identifier].vue").then(m => m.default || m)
  },
  {
    name: "institutions-institution___de",
    path: "/institutionen/:institution()",
    meta: indexGwh7fBwVneMeta || {},
    component: () => import("/usr/src/kulturpool-frontend/pages/institutions/[institution]/index.vue").then(m => m.default || m)
  },
  {
    name: "institutions-institution___en",
    path: "/en/institutions/:institution()",
    meta: indexGwh7fBwVneMeta || {},
    component: () => import("/usr/src/kulturpool-frontend/pages/institutions/[institution]/index.vue").then(m => m.default || m)
  },
  {
    name: "institutions___de",
    path: "/institutionen",
    component: () => import("/usr/src/kulturpool-frontend/pages/institutions/index.vue").then(m => m.default || m)
  },
  {
    name: "institutions___en",
    path: "/en/institutions",
    component: () => import("/usr/src/kulturpool-frontend/pages/institutions/index.vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/suche",
    meta: searchCEWJ7kIHjOMeta || {},
    component: () => import("/usr/src/kulturpool-frontend/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: searchCEWJ7kIHjOMeta || {},
    component: () => import("/usr/src/kulturpool-frontend/pages/search.vue").then(m => m.default || m)
  }
]