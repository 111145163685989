import { createDirectus, rest } from '@directus/sdk'
import type { RestClient } from '@directus/sdk'

export function useDirectusClient(): RestClient<APISchema> {
  const pubConfig = useRuntimeConfig().public

  // keep order as server is always run first and client second (otherwise you might break conditions)
  if (import.meta.client) {
    return createDirectus(pubConfig.directusCsrUrl).with(rest())
  } else {
    return createDirectus(pubConfig.directusSsrUrl).with(rest())
  }
}

export function useDirectusUrl(): URL {
  const pubConfig = useRuntimeConfig().public

  if (import.meta.client) {
    return new URL(pubConfig.directusCsrUrl)
  } else {
    return new URL(pubConfig.directusSsrUrl)
  }
}
