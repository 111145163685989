const baseVocabulary = {
  collectionObject: 'Sammlungsobjekt | Sammlungsobjekte',
  digitizedObject: 'Digitalisat | Digitalisate',
  institution: 'Institution | Institutionen',
}

export default {
  /* === metadata === */
  metadataFields: {
    // === main ===
    institution: baseVocabulary.institution,
    description: 'Beschreibung',
    // === general data ===
    generalData: 'Allgemeine Informationen',
    // ===
    title: 'Titel',
    alternative: 'Alternativtitel',
    identifier: 'Identifikator',
    objectType: 'Objekttyp',
    medium: 'Medium',
    isPartOf: 'Ist ein Teil von',
    subject: 'Thema',
    date: 'Datum',
    created: 'Datum (Erstellung)',
    coverage: 'Bezug',
    spatialCoverage: 'Räumlicher Bezug',
    temporalCoverage: 'Zeitlicher Bezug',
    extent: 'Maße / Umfang',
    currentLocation: 'Ort',
    language: 'Sprache',
    provenance: 'Provenienz',
    // === media ===
    format: 'Format',
    mediaData: 'Medieninformationen',
    // ===
    rightOfUse: 'Nutzungsrecht',
    rightsHolder: 'Rechteinhaber:in',
    mediaType: 'Medientyp',
    reuse: 'Kann ich es weiterverwenden?',
    // === entities ===
    entities: 'Personen und Entitäten',
    // ===
    creator: 'Schöpfer:in',
    publisher: 'Veröffentlicht durch',
    contributor: 'Mitwirkende:r',
    // === origin ===
    origin: 'Herkunft',
    // ===
    providedBy: 'Bereitgestellt durch',
  },
  metadataLocales: {
    reuse: {
      OPEN: 'Ja',
      RESTRICTED: 'Ja, mit Bedingungen',
      CLOSED: 'Vielleicht, mit einer Erlaubnis',
    },
    mediaType: {
      IMAGE: 'Bild',
      TEXT: 'Text',
      VIDEO: 'Video',
      SOUND: 'Ton',
      '3D': '3D',
    },
    untitled: '[Unbenannt]',
  },
  /* === components === */
  block: {
    demo_searches: 'Suchvorschlag',
    more: 'mehr',
  },
  button: {
    detailPage: 'Vollansicht',
    objectAtInstitution: 'Zum Original',
    cite: 'Zitieren',
    citeSuccess: 'Kopiert',
    citeError: 'Fehler',
    share: 'Teilen',
    shareSuccess: 'Kopiert',
    shareError: 'Fehler',
    downloads: 'Formate',
  },
  collection: {
    addFavorite: 'Zu Favoriten hinzufügen',
    dropFavorite: 'Aus Favoriten entfernen',
    navigateBack: 'Schließe und navigiere zur Startseite',
    mediaUnavailable:
      'Dieses Digitalisat kann aus rechtlichen Gründen derzeit nur bei der Partnerinstitution abgespielt werden.',
  },
  citation: {
    noDate: 'o. D.',
    retrieved: 'Zugegriffen',
    quoteLeft: '„',
    quoteRight: '“',
  },
  favorites: {
    title: 'Favoriten',
    export: 'Exportieren',
    back: 'Zurück',
    close: 'Schließen',
    deleteAll: 'Alle löschen',
    confirmDeleteAll: 'Löschung bestätigen',
    openPanel: 'Öffne Favoritenübersicht',
    dropFavorite: 'Entferne Objekt aus den Favoriten',
    columnTitleInstitution: 'Institution',
    columnTitleTitle: 'Titel',
    columnTitleInstitutionLink: 'Adresse Institution',
    columnTitleKulturpoolLink: 'Adresse Kulturpool',
  },
  filter: {
    applyAll: 'Anwenden',
    clearAll: 'Alle Filter entfernen',
    showAll: 'Alle Filter',
    searchPlaceholder: 'Suche in Filteroptionen',
    noResults: 'Keine Resultate',
    yearBefore: 'Vor',
    yearAfter: 'Nach',
    deleteFacet: 'Lösche Filter',
    backToTypes: 'Navigiere zurück zu den Filterkategorien',
    sliderLabel: 'Wähle einen Wertebereich',
  },
  image: {
    showImageInfo: 'Zeige Bildinformationen',
  },
  navigation: {
    searchTitle: 'Suche',
    menuTitle: 'Menü',
    hits: 'Treffer',
    noHits: 'Keine Resultate',
    lightColors: 'Helle Farben',
    darkColors: 'Dunkle Farben',
    autoColors: 'Systemfarben',
    contrastColors: 'Kontrastfarben',
    scrollForward: 'Digitalisate weiter scrollen',
    scrollBackward: 'Digitalisate zurück scrollen',
    closeNavigation: 'Schließe Navigationsmenü',
    openNavigation: 'Öffne Navigationsmenü',
    closeNavigationButton: 'Schließen',
    bmkoes:
      'Logo: "Bundesministerium für Kunst, Kultur, öffentlichen Dienst und Sport"',
    eu: 'Logo: "Finanziert von der Europäischen Union"',
    nhm: 'Logo: "Naturhistorisches Museum Wien"',
  },
  preview: {
    previousObject: 'Öffne Vorschau für vorheriges Objekt',
    nextObject: 'Öffne Vorschau für nächstes Objekt',
    setFavorite: 'Füge Objekt den Favoriten hinzu',
    removeFavorite: 'Entferne Objekt von Favoriten',
    fullView: 'Öffne volleständige Objektansicht',
    closePreview: 'Schließe Objektvorschau',
  },
  result: {
    loadPrevious: 'Vorherige Ergebnisse laden',
    loadMore: 'Weitere laden',
    context: 'Wählen Sie ein Kontext-Feld',
  },
  search: {
    inputPlaceholder: `Wonach suchen Sie?`,
    resetQuery: 'Setze Sucheingabe zurück',
    searchAppeal: `Verwenden Sie die Suche und Filter oberhalb
        oder wählen Sie eine unserer Beispielsuchen:`,
    showMore: `Weitere anzeigen`,
    foundNothing:
      'Wir konnten kein Objekt finden, welches Ihren Suchkriterien entspricht.',
    foundNothingAppeal:
      'Überprüfen Sie Ihren Suchbegriff und die aktiven Filter.',
  },
  sort: {
    sorting: 'Sortierung',
    sortedBy: 'Sortiert nach',
    sortBy: 'Sortiere nach',
    title: 'Titel',
    relevance: 'Relevanz',
    institution: 'Institution',
    date: 'Datum',
    openPanel: 'Öffne Sortieroptionen',
    ascending: 'aufsteigend',
    descending: 'absteigend',
  },
  swiper: {
    discoverInstitutions: `Entdecken Sie alle Institutionen`,
  },
  view: {
    switchView: 'Wechsle Ansicht',
    tiles: 'Kacheln',
    grid: 'Raster',
  },
  feedback: {
    giveFeedback: 'Feedback geben',
    hideButton: 'Blende Feedback-Button aus',
  },
  cookies: {
    declaration: `Diese Website verwendet Cookies: Neben technisch notwendigen Cookies verwenden wir auch solche,
      deren Zweck die Analyse von Webseitenzugriffen ist und Cookies, die durch Drittanbieter wie Sketchfab oder Marker.io gesetzt werden.
      Nähere Informationen finden Sie in unserer {policyLink}.`,
    generalIFrameDeclaration:
      'Um diesen Medieninhalt anzuzeigen, verwenden wir einen iFrame, der auf eine externe Quelle verweist. Bitte erlauben Sie Cookies, damit die Anzeige funktioniert.',
    sketchfabIFrameDeclaration:
      'Um das 3D-Modell dieses Digitalisats zu sehen, verwenden wir einen iFrame, der auf eine externe Quelle verweist. Bitte erlauben Sie Cookies, damit die Anzeige funktioniert.',
    acceptGeneralMediaCookies: 'Cookies aus Medieninhalten akzeptieren',
    acceptSketchfabCookies: 'Cookies aus Sketchfab Darstellungen akzeptieren',
    acceptIframes: 'Nur notwendige Cookies akzeptieren',
    acceptAll: 'Alle Cookies akzeptieren',
    acceptNecessary: 'Notwendige Cookies akzeptieren',
    privacyPolicy: 'Datenschutzerklärung',
  },
  /* === pages === */
  pages: {
    institutions: {
      pageTitle: 'Institutionen',
      institution: baseVocabulary.institution,
      onlineCatalogue: 'Online-Katalog',
      toTheDigitisedObjects: `Zu den Digitalisaten im Kulturpool`,
    },
  },
  /* === control === */
  errors: {
    backHome: 'Zur Startseite',
    typesenseMessage:
      'Etwas ging schief beim Einholen der Daten vom Kulturpool Index.',
    mongodbMessage:
      'Etwas ging schief beim Einholen der Daten von der Kulturpool Datenbank.',
    directusMessage:
      'Etwas ging schief beim Einholen der Daten von den Kulturpool Inhalten.',
    choPageMessage: 'Das gesuchte Objekt existiert nicht (mehr) im Kulturpool.',
    contentPageMessage:
      'Die gesuchte Seite existiert nicht (mehr) im Kulturpool.',
    showStack: 'Detaillierte Fehlerbeschreibung',
  },
  /* === seo === */
  seo: {
    pageTitles: {
      search: 'Suche',
      institutions: 'Institutionen',
    },
  },
}
