import { utils, write } from 'xlsx'
import saveAs from 'file-saver'
import type { ExportCho } from '~/types/exports'

export function exportToSpreadsheet(
  items: ExportCho[],
  columnTitles: string[]
) {
  const rawItems = toRaw(items)
  const exportData = rawItems.map((item) => [
    item.institutionTitle,
    item.title,
    item.isShownAt,
    item.route,
  ])
  const exportAoa = [columnTitles, ...exportData]
  const workSheet = utils.aoa_to_sheet(exportAoa)
  const workBook = utils.book_new()
  utils.book_append_sheet(workBook, workSheet, 'kulturpool-favorites')
  const csvBuffer = write(workBook, {
    bookType: 'csv',
    type: 'array',
  })
  const fileData = new Blob([csvBuffer], {
    type: 'text/csv',
  })
  saveAs(fileData, `kulturpool-favorites_${new Date().toISOString()}.csv`)
}
