import type { IndexCho } from '~/models/IndexCho'
import type { FavoriteCho } from '~/types/favorites'

export default function (cho: IndexCho | FavoriteCho) {
  // >useI18n< is a composition function must be called within setup(); use >useNuxtApp< instead
  const { $i18n } = useNuxtApp()
  const localePath = useLocalePath()

  const store = useInstitutionStore()
  const router = useRouter()

  const dataProvider = store.getByName(cho.dataProvider)

  let intermediateProvider = null
  if (cho.intermediateProvider !== null)
    intermediateProvider = store.getByName(cho.intermediateProvider)

  const institutionTitle =
    dataProvider?.title[$i18n.locale.value as LanguageCode] ||
    dataProvider?.title.de ||
    // intermediateProvider?.title[$i18n.locale.value as LanguageCode] ||
    // intermediateProvider?.title.de ||
    cho.dataProvider

  const institutionSlug =
    intermediateProvider?.slug[$i18n.locale.value as LanguageCode] ||
    intermediateProvider?.slug.de ||
    dataProvider?.slug[$i18n.locale.value as LanguageCode] ||
    dataProvider?.slug.de

  const institutionRoute = `${localePath('institutions')}/${institutionSlug}`

  // be extra careful with this route entry as it is need for caching the infinite scroll (e.g. >localePath< did not work)
  const route = router.resolve({
    name: `institutions-institution-identifier___${$i18n.locale.value}`,
    params: {
      institution: institutionSlug,
      identifier: encodeURIComponent(cho.identifier[0]),
    },
  })

  const title = cho.title?.[0] || $i18n.t('metadataLocales.untitled')

  return {
    dataProvider,
    intermediateProvider,
    institutionTitle,
    institutionSlug,
    institutionRoute,
    route,
    title,
  }
}
